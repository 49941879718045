export const LINKS = {
  a8: 'https://ancient8.gg/',
  sp3: 'https://docs.space3.gg/introduction/what-is-space3',
  term: 'https://docs.space3.gg/communities/disclaimer-and-terms-of-use',
  faq: 'https://docs.space3.gg/support/faq',
  litePaper: 'https://docs.space3.gg/introduction/what-is-space3',
  contact: 'https://hmtpexl7fbo.typeform.com/SPC3Collab',
  joinUs: 'https://bit.ly/41nIfNR',
  blog: 'https://space3.substack.com/',
}
