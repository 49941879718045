import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { CommunityService, GetAllCommunityDto } from '@/services/community'

import { CommunityWithMembersData } from '@/types/community.type'

import { CACHE_TIME, DEFAULT_PAGE_SIZE_24 } from '@/constants'

interface InfinitePagination {
  data: CommunityWithMembersData[]
  nextPage: number | undefined
}

export const useCommunities = (dto?: GetAllCommunityDto, enabled?: boolean) => {
  const { data, ...others } = useQuery({
    queryKey: ['community', JSON.stringify(dto)],
    queryFn: async () => await CommunityService.getAll(dto || {}),
    cacheTime: Infinity,
    enabled,
  })

  return { ...others, data: data?.data || [], total: data?.total }
}

export const useCommunity = (communityId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: ['community', communityId],
    enabled,
    queryFn: async () => await CommunityService.getById(communityId),
    cacheTime: CACHE_TIME,
  })
}

export const useCommunitiesInfinite = (dto: GetAllCommunityDto) => {
  const { data, ...rest } = useInfiniteQuery({
    queryFn: async ({ pageParam = 1 }: { pageParam?: number }) => {
      const result = await CommunityService.getAll({
        ...dto,
        page: pageParam,
      })

      return {
        nextPage:
          pageParam < Math.ceil(result.total / DEFAULT_PAGE_SIZE_24)
            ? pageParam + 1
            : undefined,
        data: result.data,
      } as InfinitePagination
    },
    queryKey: ['get-communities', dto],
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true,
  })

  let communitiesData: CommunityWithMembersData[] = []
  data?.pages.forEach(
    (e) => (communitiesData = [...communitiesData, ...e.data]),
  )

  return {
    data: communitiesData,
    ...rest,
  }
}
