export function defaultUndefined<T>(input?: T): T | undefined {
  if (Array.isArray(input) && input.length === 0) {
    return undefined
  }
  if (typeof input === 'string' && input === '') {
    return undefined
  }
  if (typeof input === 'number' && [NaN, Infinity].includes(input)) {
    return undefined
  }
  return input
}
