import { Button, Col, Divider, Image, Row, Space, Typography } from 'antd'

import Brand from '@/components/systems/brand'
import Socials from '@/components/systems/socials'
import LinkExternal from '@/components/systems/link-external'

import useIsMobile from '@/hooks/useIsMobile'

import Space3Logo from '@/assets/images/logo/space3-logo.svg'
import A8Logo from '@/assets/images/logo/a8-logo-only.svg'
import FooterHr from '@/assets/images/footer/footer-hr.svg'

import { LINKS } from '@/constants/url'

import './index.scss'

function FooterDesktop() {
  const isMobile = useIsMobile()
  return (
    <Row gutter={[30, 32]} justify="center" className="footer">
      <Col span={24} style={{ textAlign: 'center' }}>
        <Brand />
      </Col>

      <Col span={24} className="footer--hr">
        <Row justify="center">
          <Image
            src={FooterHr.src}
            alt="footer-hr"
            preview={false}
            width={1440}
          />
        </Row>
      </Col>

      <Col span={24} style={{ maxWidth: 1220 }}>
        <Row justify="space-between" gutter={[0, 32]}>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Space
              direction="vertical"
              size={16}
              style={isMobile ? { textAlign: 'center', width: '100%' } : {}}
            >
              <Typography.Text
                type="success"
                className="title-expanded footer--title"
              >
                Unleash the power of community and nurture brand relationships
              </Typography.Text>

              <Typography.Text>
                Create your community profile to build a loyal memberbase and
                gain visibility in the Metaverse
              </Typography.Text>

              <LinkExternal href={LINKS.joinUs}>
                <Button
                  className="footer--btn-join"
                  block
                  style={isMobile ? { height: '52px' } : {}}
                  type="primary"
                >
                  <Typography.Text type="success">Join Us</Typography.Text>
                </Button>
              </LinkExternal>
            </Space>
          </Col>

          <Col xs={24} sm={24} lg={4} offset={isMobile ? 0 : 1}>
            <Row align="middle" justify={isMobile ? 'center' : 'start'}>
              <Col>
                <LinkExternal href={LINKS.sp3}>
                  <Button
                    type="link"
                    icon={
                      <Image
                        src={Space3Logo.src}
                        alt="logo"
                        preview={false}
                        width={24}
                      />
                    }
                    className="footer-button-link"
                  >
                    <Typography.Text
                      type="secondary"
                      className="footer--list-txt"
                    >
                      Docs
                    </Typography.Text>
                  </Button>
                </LinkExternal>
              </Col>
              {isMobile && (
                <Col>
                  <Divider
                    type="vertical"
                    className="footer--vertical-divider"
                    orientation="center"
                    style={{ backgroundColor: '#0BCBC2', height: 34 }}
                  />
                </Col>
              )}
              <Col>
                <LinkExternal href={LINKS.a8}>
                  <Button
                    type="link"
                    icon={
                      <Image
                        src={A8Logo.src}
                        alt="logo"
                        preview={false}
                        width={24}
                      />
                    }
                    className="footer-button-link"
                  >
                    <Typography.Text
                      type="secondary"
                      className="footer--list-txt"
                    >
                      Ancient8
                    </Typography.Text>
                  </Button>
                </LinkExternal>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={4}>
            <Space
              direction="vertical"
              size={8}
              align={isMobile ? 'center' : 'start'}
              style={{ display: 'flex' }}
            >
              <LinkExternal href={LINKS.term}>
                <Button type="link" className="footer-button-link">
                  <Typography.Text
                    type="secondary"
                    className="footer--list-txt"
                  >
                    Disclaimer & Terms of Use
                  </Typography.Text>
                </Button>
              </LinkExternal>

              <LinkExternal href={LINKS.contact}>
                <Button type="link" className="footer-button-link">
                  <Typography.Text
                    type="secondary"
                    className="footer--list-txt"
                  >
                    Partnership Contact
                  </Typography.Text>
                </Button>
              </LinkExternal>

              <LinkExternal href={LINKS.litePaper}>
                <Button type="link" className="footer-button-link">
                  <Typography.Text
                    type="secondary"
                    className="footer--list-txt"
                  >
                    Litepaper
                  </Typography.Text>
                </Button>
              </LinkExternal>

              <LinkExternal href={LINKS.blog}>
                <Button type="link" className="footer-button-link">
                  <Typography.Text
                    type="secondary"
                    className="footer--list-txt"
                  >
                    Blog
                  </Typography.Text>
                </Button>
              </LinkExternal>
            </Space>
          </Col>

          <Col xs={24} lg={6}>
            <Row justify={isMobile ? 'center' : 'end'}>
              <Socials color="#4D5A66" />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FooterDesktop
