import { Button, Space } from 'antd'

import IonIcon from '@/components/systems/ion-icon'
import LinkExternal from '@/components/systems/link-external'

import { useCommunity } from '@/hooks/community-profile/useCommunities'

import configs from '@/configs'

import './index.scss'

const {
  community: { SP3CommunityId },
} = configs

interface SocialsProps {
  color?: string
}

const Socials = ({ color }: SocialsProps) => {
  const { data: community } = useCommunity(SP3CommunityId)

  const socials: any = {
    telegram: community?.telegramLink || '',
    discord: community?.discordLink || '',
    facebook: community?.facebookLink || '',
    twitter: community?.twitterLink || '',
    youtube: community?.youtubeLink || '',
  }

  return (
    <Space size={16}>
      {Object.keys(socials).map((name) => (
        <LinkExternal href={socials[name]} key={name}>
          <Button
            shape="circle"
            type="text"
            icon={
              <IonIcon
                className="social-community--icons"
                name={name}
                style={{ fontSize: 32, color }}
              />
            }
            disabled={!socials[name]}
            className="social-community--button"
          />
        </LinkExternal>
      ))}
    </Space>
  )
}

export default Socials
